import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "banner": "images/banner-home.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "welcome-to-de-la-salle-college"
    }}>{`Welcome to De La Salle College`}</h1>
    <h3 {...{
      "id": "founded-in-1912-de-la-salle-is-an-independent-catholic-college-for-boys-in-years-5-12-located-on-three-campuses-in-malvern-and-malvern-east-our-college-provides-a-respectful-and-encouraging-environment-where-strong-family-values-are-reinforced"
    }}>{`Founded in 1912, De La Salle is an independent Catholic College for boys in Years 5-12. Located on three campuses in Malvern and Malvern East, our College provides a respectful and encouraging environment where strong family values are reinforced.`}{`*`}{`*`}</h3>
    <p>{`We have educated our young men for over a century in the Lasallian tradition, preparing them to be successful citizens in today’s society. Our principle Mr Peter Houlihan extends his welcome and encourages you to discover the community that is De La Salle College.`}</p>
    <p><div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "56.42857142857143%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "",
            "src": "https://www.youtube.com/embed/m52YrJnicUs?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "allowFullScreen": true
          }}></iframe>{` `}</div>{` `}</div></p>
    <p><div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "56.42857142857143%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "",
            "src": "https://www.youtube.com/embed/ojp524nqKNM?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "allowFullScreen": true
          }}></iframe>{` `}</div>{` `}</div></p>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://www.trybooking.com/BKPFR"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "768px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60416666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABntUYXCVDN//EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMREyH/2gAIAQEAAQUCz1Sw72I41kaoKTP/xAAXEQEBAQEAAAAAAAAAAAAAAAABAAMT/9oACAEDAQE/ATNubf/EABgRAAMBAQAAAAAAAAAAAAAAAAABAgQT/9oACAECAQE/AXolneT/xAAbEAACAgMBAAAAAAAAAAAAAAAAEQExECJBcf/aAAgBAQAGPwLhMUzVrwoax//EABsQAAICAwEAAAAAAAAAAAAAAAABESExQWGx/9oACAEBAAE/IUylFiqRR2TFfhqJ1+2RJddGc5Z//9oADAMBAAIAAwAAABC/7//EABcRAAMBAAAAAAAAAAAAAAAAAAABQWH/2gAIAQMBAT8QTcNj/8QAHBEAAgEFAQAAAAAAAAAAAAAAAREAIUFxgdHw/9oACAECAQE/EBSRrjsVY+3P/8QAHBABAAMBAAMBAAAAAAAAAAAAAQARITFBUaHw/9oACAEBAAE/EL2wPbqAXirCK3gQ7gb0t6KhAt/rzAFJMVPxYzkrAGp//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Year 7 2022 Information Session",
            "title": "Year 7 2022 Information Session",
            "src": "/static/ac4cf44899df627438a0f979037c128d/212bf/Year7_2022_information_session.jpg",
            "srcSet": ["/static/ac4cf44899df627438a0f979037c128d/7809d/Year7_2022_information_session.jpg 192w", "/static/ac4cf44899df627438a0f979037c128d/4ecad/Year7_2022_information_session.jpg 384w", "/static/ac4cf44899df627438a0f979037c128d/212bf/Year7_2022_information_session.jpg 768w", "/static/ac4cf44899df627438a0f979037c128d/5ef17/Year7_2022_information_session.jpg 1152w", "/static/ac4cf44899df627438a0f979037c128d/ac99c/Year7_2022_information_session.jpg 1536w", "/static/ac4cf44899df627438a0f979037c128d/3a9e8/Year7_2022_information_session.jpg 2480w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      